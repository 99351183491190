import React from "react"
import { useSelector } from "react-redux"
import { Flex } from "src/styled"
import { Loading } from "src/components/common"
import MobileList from "./MobileList"
import DesktopList from "./DesktopList"

export default function Menu() {
    const { isLoading } = useSelector(state => state.menu)

    if (isLoading) {
        return (
            <Flex
                alignItems="center"
                justifyContent="center"
                flex={1}
                height="100%"
                style={{ marginTop: "10px" }}
            >
                <Loading />
            </Flex>
        )
    }

    return (
        <>
            <DesktopList />
            <MobileList />
        </>
    )
}
