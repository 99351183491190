import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Element } from "react-scroll"
import Card from "./Card"
import {
    BigContainer,
    Container,
    Item,
    Wrapper,
    SearchWrapper,
} from "./components"
import Search from "src/components/Search"
import { setMenuSearch } from "src/redux/menu/actions"
import { debounce, getItemType } from "src/utils"
import { itemTypes } from "src/constants"

export default function MobileList() {
    const dispatch = useDispatch()
    const { allCategories, filteredAndSearchedMenu, ingredients } = useSelector(
        state => state.menu
    )

    function categorizeItems(items, allCategories) {
        const list = allCategories.map(category => ({
            name: category,
            items: [],
        }))

        const cache = {}

        for (let i = 0; i < items.length; i++) {
            const item = items[i]
            let index = -1
            if (cache[item.category]) {
                index = cache[item.category]
            } else {
                index = list.findIndex(
                    category => category.name === item.category
                )
                cache[item.category] = index
            }

            list[index].items.push(item)
        }

        return list
    }

    const debounceOnSearch = debounce(
        search => dispatch(setMenuSearch(search)),
        500
    )

    function getDescription(item) {
        const itemType = getItemType(item.id)
        const itemDescription = {
            [itemTypes.set]: getSetDescription,
            [itemTypes.product]: getProductDescription,
            [itemTypes.ingredient]: () => undefined,
        }
        return itemDescription[itemType](item)
    }

    function getSetDescription(set) {
        return set.products.map(
            product => `${product.name} x ${product.numberOfPieces}`
        )
    }

    function getProductDescription(product) {
        return product.ingredients.map(ingredient => `${ingredient.name} `)
    }
    const order = [
        "Zestaw",
        "Sushi",
        "Przystawki",
        "Sashimi",
        "Sosy",
        "Dodatki",
        "Desery",
        "Dania ciepłe",
        "Napoje alkoholowe",
        "Napoje bezalkoholowe",
    ]

    let filteredSortedAndSearchedMenu = filteredAndSearchedMenu.reduce(
        (acc, element) => {
            if (element.name == "WARSAWianka") {
                return [element, ...acc]
            }
            return [...acc, element]
        },
        []
    )

    return (
        <BigContainer>
            <SearchWrapper>
                <Search onChange={debounceOnSearch} />
            </SearchWrapper>
            <Container>
                <ul>
                    {allCategories
                        .filter(element => element !== "Alcohol")
                        .sort((a, b) => {
                            let indexA = order.indexOf(a)
                            let indexB = order.indexOf(b)

                            if (indexA === -1 && indexB === -1)
                                return a.localeCompare(b)
                            if (indexA === -1) return 1
                            if (indexB === -1) return -1
                            return indexA - indexB
                        })
                        .map((category, index) => (
                            <li key={index}>
                                <Item
                                    activeClass="active-navigation"
                                    smooth={true}
                                    duration={500}
                                    to={category}
                                    spy={true}
                                    containerId="container"
                                >
                                    {category === "Addons"
                                        ? "Dodatki"
                                        : category && category === "Beverages"
                                        ? "Napoje"
                                        : category}
                                </Item>
                            </li>
                        ))}
                </ul>
            </Container>

            <Wrapper id="container">
                {categorizeItems(
                    filteredSortedAndSearchedMenu,
                    allCategories
                ).map((categoryList, index) => {
                    const { items: categoryItems, name } = categoryList

                    return (
                        <Element name={name} key={index}>
                            {categoryItems
                                .filter(
                                    element => element.category !== "Alcohol"
                                )
                                .map(item => (
                                    <Card
                                        isInRestaurantMenu={false}
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        price={item.price}
                                        category={item.category}
                                        description={getDescription(item)}
                                        desc={item.description}
                                        imageURL={item.imageURL}
                                    />
                                ))}
                        </Element>
                    )
                })}
            </Wrapper>
        </BigContainer>
    )
}
